<template>
  <div id="apphandle">
    <div id="navwrap">
      <Nav />
    </div>
    <router-view />
    <div id="booking2">
      <Booking />
    </div>
    <!-- <div class="fpspacer"></div> -->
    <div id="footer">
      <Footer />
    </div>
  </div>
</template>

<script>
import Nav from '@/components/Nav.vue'
import Footer from "./components/Footer.vue";
import Booking from './components/Booking.vue';


export default {
  setup() {


    return {}
  },
  components: {
    Nav, Footer,
    Booking,
  }
}
</script>

<style lang="scss">
@import "./styles/_variables.scss";

* {
  margin: 0 !important;
}

#app {
  font-family: Kohinoor Bangla Reg;
  text-align: center;
  // display:flex;
  // flex-direction: column;
  // align-items: center;
  // width:100% !important;

  #apphandle {
    min-height: 60vh;
    // position: relative;
    overflow: hidden;
  }

  body {
    margin: 0 !important;
    width: 100%;
  }

  a {
    font-family: Kohinoor Bangla Reg;
  }

  #navwrap {
    position: fixed;
    -webkit-transform: translateZ(0);
    width: 100%;
    top: 0px;
    z-index: 100;
    background-color: map-get($cs, bg);
  }

  #booking2 {
    display: none;

    @media (max-width: 800px) {

      display: flex;
      align-items: center;
      position: fixed;
      bottom: 20px;
      right: 60px;
      // position: relative;
      z-index: 999;
    }

    @media (max-width: 500px) {}
  }


  #footer {
    // position: fixed;
    width: 100%;
    z-index: 100;
    bottom: 0 !important;

    @media (max-width: 500px) {
      position: unset !important;
    }
  }
  .fpspacer {
    height:350px;
    @media (max-width: 500px) {
      height:0;
    }
  }
}
</style>